import {useLazyQuery, useQuery} from "@apollo/client";
import {GetParticipatingPlayer} from "../../"

interface Props {}

export const useGetParticipatingPlayer = () => {
    return useLazyQuery(GetParticipatingPlayer);
};


