import React, { useEffect } from 'react';
import './App.css';
import ParticipatingCard from "./components/PDF/ParticipatingCard";
import { useGetParticipatingPlayer } from "./graphql";
import { useParams } from "react-router-dom";

function ParticipatingPlayer() {
  const { id } = useParams();
  const [getPlayer, { data: dataPlayer, loading }] = useGetParticipatingPlayer();
  console.log("**** /participating-player/")
  console.log("id",id)

  useEffect(() => {
    console.log("entere useEffect")
    if (id && id !== "") {
      getPlayer({
        variables: { id },
        fetchPolicy: "network-only"
      });
    }
  }, [id]);
  useEffect(() => {
    console.log("Participationplayer",dataPlayer)
  }, [dataPlayer]);

  

  if (loading || !dataPlayer) {
    return <div>Loading...</div>;
  }

  return (
    <ParticipatingCard Participationplayer={dataPlayer.GetParticipatingPlayer} />
  );
}

export default ParticipatingPlayer;
