import React from "react";
import { Page, Text, Document, StyleSheet, View, Font, PDFViewer } from "@react-pdf/renderer";
import { FaArrowRight } from 'react-icons/fa';
import { Image } from "@react-pdf/renderer";
// Register fonts
Font.register({
  family: "Montserrat-Arabic",
  fonts: [
    {
      src: "/fonts/Montserrat-Arabic-Regular.ttf",
      fontStyle: "normal",
      fontWeight: 400,
    },
    {
      src: "/fonts/Montserrat-Arabic-Medium.ttf",
      fontStyle: "normal",
      fontWeight: 700,
    },
  ]
});

// Styles for PDF
const styles = StyleSheet.create({
  body: {
    fontFamily: "Montserrat-Arabic",
    backgroundColor: "#fff",
    padding: 14,
  },
  header: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: 10,
  },
  matchContainer: {
    border: "1px solid #555",
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
    textAlign: "center",
  },
  matchDate: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 10,
  },
  teamContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  teamContainerGoal: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop:'30px'
  },
  goal:{
    flexDirection: 'row',
    flexWrap: 'nowrap',
  }
  ,
  teamName: {
    fontSize: 14,
    fontWeight: 700,
    width: "45%",
  },
  score: {
    fontSize: 14,
    fontWeight: 700,
  },
  vs: {
    fontSize: 14,
    fontWeight: 700,
    marginHorizontal: 10,
  },
  playerSection: {
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    border: "1px solid #555",
    borderRadius: 5,
  },
  playerTitle: {
    fontSize: 14,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: 5,
  },
  playerList: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  playerItem: {
    width: "100%",
    padding: 5,
    marginBottom: 5,
  },
  playerName: {
    fontSize: 10,
    fontWeight: 400,
    textAlign: "center",
  },
  refereeSection: {
    marginTop: 20,
    borderTop: "1px solid #555",
    paddingTop: 10,
  },
  refereeTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
    textAlign: "center",
  },
  refereeNames: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 5,
  },
  refereeName: {
    fontSize: 10,
    fontWeight: 400,
  },
  arrow: {
    color: "green",  // Fill the arrow with green color
    marginRight: 5,
  }
});

const MatchResult = ({ matchData }: { matchData: any }) => {
  const { firstTeam, secondTeam, firstTeamGoal, secondTeamGoal, arbitre , firstTeamScorersMatch , secondTeamScorersMatch } = matchData;

  const getPlayerName = (player: { player: any }) => player?.player?.person.first_name +' '+ player?.player?.person.second_name;
console.log("matchData:",matchData)
const filterPlayers = (team: any, isStarter: any) => {
  return team?.participatingPlayers?.filter((player: any) => {
    return player.participatingPlayersMatches[0]?.starter === isStarter;
  }) || [];
};

  const firstTeamStarters = filterPlayers(firstTeam, true);
  const firstTeamSubs = filterPlayers(firstTeam, false);
  const secondTeamStarters = filterPlayers(secondTeam, true);
  const secondTeamSubs = filterPlayers(secondTeam, false);

  const formatDate = (dateString :string) => {
    if (!dateString) return '';
    const [date, time] = dateString.split(' ');
    const [hour, minute] = time.split(':');
    return `${date} ${hour}:${minute}`;
  };

  return (
    <PDFViewer style={{ minHeight: "calc(100vh - 25px)", minWidth: "calc(100vw - 10px)" }}>
      <Document>
        <Page size="A4" style={styles.body}>
        <View style={[styles.matchContainer, { flexDirection: "row", alignItems: "center", justifyContent: "space-between" }]}>
        {matchData?.league?.club?.logo && <Image
          style={{ width: "20mm", height: "20mm" }}
          src={matchData?.league?.club?.logo || "/"}
        />}
         <Text style={[styles.matchDate, { textAlign: "right", flex: 1 }]}>{matchData?.league?.name}</Text>
      </View>
          {/* Match header */}
          <Text style={styles.header}>نتيجة المباراة</Text>

          {/* Match details */}
          <View style={styles.matchContainer}>
            <Text style={styles.matchDate}>{formatDate(matchData.date)}</Text>
            <View style={styles.teamContainer}>
              
              <Text style={styles.teamName}>{firstTeam?.team?.name || ''}</Text>
              <Text style={styles.score}>{firstTeamGoal}</Text>
              <Text style={styles.vs}>-</Text>
              <Text style={styles.score}>{secondTeamGoal}</Text>
              <Text style={styles.teamName}>{secondTeam?.team?.name || ''}</Text>
              
            </View>
           
            <View style={styles.teamContainerGoal}>
              <View style={{ width: "45%" }}>
                <View style={styles.playerList}>
                  {firstTeamScorersMatch?.map((goal : any)   => (
                    <View key={goal.id} style={styles.playerItem}>
                      <Text style={styles.playerName}>{goal?.time}' {goal?.participating_player?.player.person.second_name} {goal?.participating_player?.player.person.first_name} </Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ width: "45%" }}>
                <View style={styles.playerList}>
                  {secondTeamScorersMatch?.map((goal : any) => (
                    <View key={goal.id} style={styles.playerItem}>
                     <Text style={styles.playerName}>{goal?.time}'  {goal?.participating_player?.player.person.second_name} {goal?.participating_player?.player.person.first_name} </Text>
                     </View>
                  ))}
                </View>
              </View>
            </View>
            
          </View>

          {/* Players section */}
          <View style={styles.playerSection}>
            <Text style={styles.playerTitle}>اللاعبين الاساسي</Text>
            <View style={styles.teamContainer}>
              <View style={{ width: "45%" }}>
                <View style={styles.playerList}>
                  {firstTeamStarters.map((player : any)   => (
                    <View key={player.id} style={styles.playerItem}>
                      <Text style={styles.playerName}>{getPlayerName(player)}</Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ width: "45%" }}>
                <View style={styles.playerList}>
                  {secondTeamStarters.map((player : any) => (
                    <View key={player.id} style={styles.playerItem}>
                      <Text style={styles.playerName}>{getPlayerName(player)}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>

          {/* Substitutes section */}
          <View style={styles.playerSection}>
            <Text style={styles.playerTitle}>اللاعبين الاحتياطي</Text>
            <View style={styles.teamContainer}>
              <View style={{ width: "45%" }}>
              <View style={styles.playerList}>
                  {firstTeamSubs?.map((player: any) => (
                    <View key={player.id} style={styles.playerItem}>
                      <FaArrowRight style={styles.arrow} />
                      <Text style={styles.playerName}>{getPlayerName(player)}</Text>
                      
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ width: "45%" }}>
              <View style={styles.playerList}>
                  {secondTeamSubs?.map((player: any) => (
                    <View key={player.id} style={styles.playerItem}>
                      <Text style={styles.playerName}>{getPlayerName(player)}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
          <View style={styles.refereeSection}>
            <Text style={styles.refereeTitle}>البطاقات</Text>
            <View style={styles.teamContainer}>
              <View style={{ width: "45%" }}>
              <View style={styles.playerList}>
                  {matchData.firstTeamCards?.map((player: any) => (
                    <View key={player.id} style={styles.playerItem}>
                      <FaArrowRight style={styles.arrow} />
                      <Text style={styles.playerName}> {player?.type === 'yellow' ? 'صفراء' : player?.type === 'red' ? 'حمراء' : ''} {player?.date} د'{player?.player}</Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={{ width: "45%" }}>
              <View style={styles.playerList}>
              {matchData.secondTeamCards?.map((player: any) => (
                    <View key={player.id} style={styles.playerItem}>
                      <FaArrowRight style={styles.arrow} />
                      <Text style={styles.playerName}> {player?.type === 'yellow' ? 'صفراء' : player?.type === 'red' ? 'حمراء' : ''} {player?.date} د'{player?.player}</Text>
                      
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>

          {/* Referees section */}
          <View style={styles.refereeSection}>
            <Text style={styles.refereeTitle}>الحكام</Text>
            <View style={styles.refereeNames}>
              <Text style={styles.refereeName}>{arbitre?.Arbitre1 || ''}</Text>
              <Text style={styles.refereeName}>{arbitre?.Arbitre2 || ''}</Text>
              <Text style={styles.refereeName}>{arbitre?.Arbitre3 || ''}</Text>
              <Text style={styles.refereeName}>{arbitre?.Arbitre4 || ''}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default MatchResult;
