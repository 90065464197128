import { gql } from "@apollo/client";

export const GetParticipatingPlayer = gql`
    query GetParticipatingPlayer($id: ID!) {
        GetParticipatingPlayer(id: $id) {
            id
            number
            player {
                id
                person {
                    id
                    personal_picture
                    first_name
                    second_name
                    third_name
                    tribe
                    phone
                    card_number
                    date_birth
                }
                team {
                    id
                    name
                    logo
                    club {
                        id
                        name
                        logo
                    }
                }
            }
            participating_team {
                id
                team {
                    name
                    club {
                        name
                    }
                }
                league {
                    id
                    description
                }
            }
        }
    }
`;
