import React, { useState } from "react";

import {Page, Text, Image, Document, StyleSheet, View, Font, PDFViewer} from "@react-pdf/renderer";
import QRCode from "qrcode";
import dayjs from "dayjs";
import { Player } from "../../graphql";

interface Props {
    Participationplayer?: any;
}

Font.register({
    family: "Montserrat-Arabic",
    fonts: [
        {
            src: "/fonts/Montserrat-Arabic-Regular.ttf",
            fontStyle: "normal",
            fontWeight: 400,
        },
        {
            src: "/fonts/Montserrat-Arabic-Medium.ttf",
            fontStyle: "normal",
            fontWeight: 700,
        },
    ]
});

function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
}

const styles = StyleSheet.create({
    body: {
        fontFamily: "Montserrat-Arabic",
        backgroundColor: "#fff",
        fontSize: 12,
        padding: 14,
    },

    smFont: {
        fontSize: "9",
        color: "#888",
    },

    horizontalDivider: {
        position: "absolute",
        top: 0,
        left: "50%",
        height: "100%",
        textAlign: "center",
        borderLeft: `2px dashed #333}`,
    },

    verticalDivider: {
        position: "absolute",
        top: "50%",
        left: 0,
        width: "100%",
        textAlign: "center",
        borderTop: `2px dashed #333`,
    },
})

const ParticipatingCard = ({ Participationplayer }: Props) => {
    let forceUpdate = useForceUpdate();
    const player = Participationplayer.player
    const apiUrl = "https://api.omkooora.com"
    // const apiUrl = "http://localhost:7000"

    const printUrl = "https://print.omkooora.com"
    console.log(player)
    let canvas: any = null;
    let qrCodeGenerator = (qrcode: any) => {
        canvas = document.createElement("canvas");
        QRCode.toCanvas(canvas, qrcode, {
            margin: 2,
        });
        return canvas.toDataURL();
    };
    console.log("Image URL: ", `https://api.omkooora.com/images/${player?.person?.personal_picture}`)
    console.log("player",player)

    return (
        <PDFViewer style={{ minHeight: "calc(100vh - 25px )", minWidth: "calc(100vw - 10px )" }}>
            <Document>
                <Page orientation={"landscape"} style={styles.body} size={"A7"} >
                        
                    <View style={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center", border: "1px solid #555", justifyContent: "space-between"}}>
                        <View style={{display: "flex", flexDirection: "row", width: "95%", height: "3cm", alignItems: "center", justifyContent: "space-between"}}>
                            {/* section 1 */}
                            <Image
                                style={{ width: "20mm", height: "20mm", marginLeft: "5mm" }}
                                src={qrCodeGenerator(`${printUrl}/#/${player?.id}`)}
                            />
                            
                            {/* section 2 */}
                            {player?.team?.club?.logo
                                        ?
                                        <Image
                                            style={{ width: "20mm", height: "20mm", marginLeft: "5mm" }}
                                            src={`${player?.team?.club?.logo}`}
                                          
                                        />
                                        : ""
                                    }
                            
                        </View>

                        <View style={{display: "flex", flexDirection: "row", width: "100%", height: "4cm", alignItems: "center", justifyContent: "space-between", padding: "0.5cm"}}>
                            {/* section 1 */}
                            <View style={{flex: 1}}>
                                <View style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "30mm"}}>
                                    {/*logo*/}
                                    {player?.person?.personal_picture 
                                        ?
                                        <Image
                                            style={{ width: "20mm", height: "20mm", marginLeft: "5mm" }}
                                            src={`https://api.omkooora.com/images/${player?.person?.personal_picture}`}
                                          
                                        />
                                        : <View style={{width: "100%", height: "2.5cm", border: "1px solid #555"}}/>
                                    }

                                </View>
                            </View>

                            {/* section 2 */}
                            <View style={{flex: 2.5}}>
                                <View style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                    <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                        <Text style={{fontSize: 7, fontWeight: 500, marginRight: 5}}>
                                            {`${player?.team?.name}`}
                                        </Text>
                                        <Text style={{fontSize: 7, fontWeight: 600}}>الفريق : </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                        <Text style={{fontSize: 7, fontWeight: 500, marginRight: 5}}>
                                            {`${player?.person?.first_name} ${player?.person?.second_name} ${player?.person?.third_name} ${player?.person?.tribe}`}
                                        </Text>
                                        <Text style={{fontSize: 7, fontWeight: 600}}>الاسم الكامل : </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                        <Text style={{fontSize: 7, fontWeight: 500, marginRight: 5}}>
                                            {`${player?.person?.date_birth} (${dayjs(player?.person?.date_birth).locale("ar").fromNow(true)})`}
                                        </Text>
                                        <Text style={{fontSize: 7, fontWeight: 600}}>تاريخ الميلاد : </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                        <Text style={{fontSize: 7, fontWeight: 500, marginRight: 5}}>
                                            {`${player?.person?.card_number}`}
                                        </Text>
                                        <Text style={{fontSize: 7, fontWeight: 600}}>الرقم المدني : </Text>
                                    </View>
                                    <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                                        <Text style={{fontSize: 7, fontWeight: 500, marginRight: 5}}>
                                            {`${Participationplayer?.participating_team?.league?.description}`}
                                        </Text>
                                        <Text style={{fontSize: 7, fontWeight: 600}}>البطولة : </Text>
                                    </View>
                                    
                                </View>
                                
                            </View>
                        </View>
                    </View>
                </Page>
                <Page orientation={"landscape"} style={styles.body} size={"A7"} >

                    <View style={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center", border: "1px solid #555", justifyContent: "center"}}>
                    <View style={{ 
                                    display: "flex", 
                                    flexDirection: "row", 
                                    width: "100%", 
                                    height: "3cm", 
                                    alignItems: "center", 
                                    justifyContent: "center", 
                                    gap: "10mm" // Adjust space between images
                                }}>
                                    {/* Section 1 */}
                                  

                               

                                    {/* Section 2 */}
                                    {player?.team?.club?.logo && (
                                        <Image
                                            style={{ width: "20mm", height: "20mm" }}
                                            src={`${player?.team?.club?.logo}`}
                                        />
                                    )}

                                    {player?.team?.logo && (
                                        <Image
                                            style={{ width: "20mm", height: "20mm" }}
                                            src={`${player?.team?.logo}`}
                                        />
                                    )}
                                </View>

                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};


export default ParticipatingCard;